import moment, { DurationInputArg1, DurationInputArg2 } from "moment";

import { IOption } from "@/components/common/AutocompleteWithTooltip";
import { CountryCode, IPeriod, UserTypes } from "@/types";

export const copIntlCountries = {
  uk: "United Kingdom",
  fr: "SEPAmail(FR,NL)",
  // Commenting Swift Countries for now
  // sw: "SWIFT",
  bf: "Nordics (DK, FI, NO, SE, GL, FO)",
  epc: "EU (EPC)",
  br: "Brazil",
  in: "India",
  id: "Indonesia",
  de: "Germany",
  mx: "Mexico",
  np: "Nepal",
  ng: "Nigeria",
  pk: "Pakistan",
  vn: "Vietnam",
  us: "United States",
  global: "Global",
};

export const userType = [
  { text: "Operations", value: UserTypes.ADMIN },
  { text: "Developer", value: UserTypes.DEVELOPER },
];

export const ROWS_IN_TABLE = [10, 25, 50];

export const ML_SECONDS_IN_DAY = 3600 * 24 * 1000;
export const ML_SECONDS_IN_HOUR = 3600 * 1000;

export const TIME_FORMAT = "DD/MM/YYYY HH:mm";
export const TIME_WITH_SECONDS = "DD/MM/YYYY HH:mm:ss";

export const DEVELOPER_TYPE = [
  { value: "internal", title: "Internal developer" },
  { value: "external", title: "External developer" },
];

export const subtract = (amount: DurationInputArg1, units: DurationInputArg2) => {
  return moment().subtract(amount, units).toISOString();
};

export const DE_BANK_ID_TYPES = ["SWIFT ID"] as const;
export const DE_ACCOUNT_ID_TYPES = ["Account Number", "IBAN"] as const;

export const NP_ACCOUNT_ID_TYPES = ["Account Number"] as const;

export const IN_BANK_ID_TYPES = ["SWIFT ID", "INFSC"] as const;
export const IN_ACCOUNT_ID_TYPES = ["Account Number"] as const;

export const IS_PK_BANK_ID_TYPES = ["SWIFT ID"] as const;
export const IS_PK_ACCOUNT_ID_TYPES = ["Account Number"] as const;

export const BF_ORG_ID_TYPES = ["VAT", "TIN"] as const;

export const US_BANK_ID_TYPES = ["SWIFT ID", "USABA"] as const;
export const US_ACCOUNT_ID_TYPES = ["Account Number"] as const;

export const BR_BANK_ID_TYPES = ["SWIFT ID"] as const;
export const BR_ACCOUNT_ID_TYPES = ["IBAN"] as const;
export const BR_ACCOUNT_HOLDER_ID_TYPES = ["NTLID"] as const;

export const MX_BANK_ID_TYPES = ["SWIFT ID"] as const;
export const MX_ACCOUNT_ID_TYPES = ["CLABE"] as const;
export const MX_ACCOUNT_HOLDER_ID_TYPES = ["NTLID"] as const;
export const ACCOUNT_HOLDER_ID_TYPES_MAP = {
  NTLID: "Tax ID",
} as const;

export const TIME_PERIODS: IPeriod[] = [
  {
    name: "24 hours",
    value: ML_SECONDS_IN_DAY,
    labelCount: 24,
    granularity: "hour",
    until: new Date().toISOString(),
    since: subtract(24, "hours"),
  },
  {
    name: "7 days",
    value: ML_SECONDS_IN_DAY * 7,
    labelCount: 7,
    granularity: "day",
    until: new Date().toISOString(),
    since: subtract(7, "days"),
  },
  {
    name: "30 days",
    value: ML_SECONDS_IN_DAY * 30,
    labelCount: 30,
    granularity: "day",
    until: new Date().toISOString(),
    since: subtract(30, "days"),
  },
  {
    name: "12 months",
    value: ML_SECONDS_IN_DAY * 365,
    labelCount: 12,
    granularity: "month",
    until: new Date().toISOString(),
    since: subtract(12, "months"),
  },
];

export const PRODUCTS = {
  INTERNAL: "UK - COP OUTBOUND API [DEFAULT]",
  EXTERNAL: "UK - COP API [DEFAULT]",
  ACCOUNTS_VERIFICATION: "GLOBAL - ACCOUNT VERIFICATION REQUESTOR",
  NORDICS_INTERNAL: "Nordics - COP OUTBOUND API",
  NORDICS_EXTERNAL: "Nordics - COP INBOUND API",
  IN_REQUESTER: "IN - REQUESTER",
  PK_REQUESTER: "PK - REQUESTER",
  DE_REQUESTER: "DE - REQUESTER",
  US_REQUESTER: "US - REQUESTER",
  ID_REQUESTER: "ID - REQUESTER",
};

export const colors = {
  adminBankLabelText: "rgba(255, 174, 27)",
  adminBankLabel: "rgba(255, 174, 27, 0.1)",
  activeFlagBackground: "#D4EFEC",
  inactiveFlagBackground: "rgba(255, 222, 230, 1)",
  linksNormal: "#009DE9",
  brandPrimary: "#2EB895",
  brandSecondary: "#044D72",
  labelNew: "rgba(7, 148, 83, 0.15)",
  labelImproved: "rgba(7, 148, 83, 0.15)",
  labelFixed: "#FFEADE",
  activeColor: "#0A9485",
  inactiveColor: "#72003D",
};

export enum SERVICE_TYPES {
  RESPONSE = "RESPONSE",
  REQUEST = "REQUEST",
}

export const getReasonCodes = (hideResponseCodes?: boolean) =>
  [
    { name: "ANNM", value: "ANNM", tooltip: "ANNM (Account Name does not match)" },
    { name: "MBAM", value: "MBAM", tooltip: "MBAM (There may be a match on the Account Name)" },
    { name: "BANM", value: "BANM", tooltip: "BANM (Business Account Name matches)" },
    { name: "PANM", value: "PANM", tooltip: "PANM (Personal Account Name matches)" },
    { name: "BAMM", value: "BAMM", tooltip: "BAMM (Business Account Name may match)" },
    { name: "PAMM", value: "PAMM", tooltip: "PAMM (Personal Account Name may match)" },
    { name: "AC01", value: "AC01", tooltip: "AC01 (Incorrect Account Number)" },
    { name: "IVCR", value: "IVCR", tooltip: "IVCR (Invalid Customer Reference)", hide: hideResponseCodes },
    { name: "ACNS", value: "ACNS", tooltip: "ACNS (Account Type not supported for CoP)" },
    { name: "OPTO", value: "OPTO", tooltip: "OPTO (Opted out of the CoP Scheme)" },
    { name: "CASS", value: "CASS", tooltip: "CASS (Account has been switched)" },
    { name: "SCNS", value: "SCNS", tooltip: "SCNS (Sort Code not supported by Endpoint)" },
  ].filter(v => !v.hide);
export const getJPMReasonCodes = [
  { name: "AC00", value: "AC00", tooltip: "AC00 (Open)" },
  { name: "AC05", value: "AC05", tooltip: "AC05 (Closed Account Number)" },
  { name: "AC99", value: "AC99", tooltip: "AC99 (Cannot Confirm)" },
  { name: "RJCT", value: "RJCT", tooltip: "RJCT (Reject)" },
];

export const RESPONSE_MATCH = [
  { name: "Matched", value: "Matched", tooltip: "Matched" },
  { name: "Partial Match", value: "Partial Match", tooltip: "Partial Match" },
  { name: "Unable to Match", value: "Unable to Match", tooltip: "Unable to Match" },
  { name: "No Match", value: "No Match", tooltip: "No Match" },
];

export const NORDICS_RESPONSE_MATCH = [
  { name: "Matched", value: "MATCHED", tooltip: "Matched" },
  { name: "Partial Match", value: "PARTIAL_MATCH", tooltip: "Partial Match" },
  { name: "Unable to Match", value: "UNABLE_TO_MATCH", tooltip: "Unable to Match" },
  { name: "No Match", value: "NO_MATCH", tooltip: "No Match" },
];

export const ACCOUNT_TYPE = [
  { name: "Personal", value: "Personal", tooltip: "Accounts of individuals" },
  { name: "Business", value: "Business", tooltip: "Accounts of entities/organisations" },
];

export const RESPONSE_SWIFT = [
  {
    name: "FAIL",
    value: "FAIL",
    tooltip: "The account identification provided was not successfully validated to an account that can receive funds",
  },
  {
    name: "INCO",
    value: "INCO",
    tooltip:
      "The account identification provided was successfully validated to an account that can receive funds, however, some additional account checks will be performed at transaction time",
  },
  {
    name: "PASS",
    value: "PASS",
    tooltip: "The account identification was successfully validated to an account that can receive funds",
  },
  {
    name: "WARN",
    value: "WARN",
    tooltip:
      "The account identification was not successfully validated to an account that can receive funds, however, the responder lacks some information to provide a definite answer",
  },
];

export const matchedSwift = (hideReasonCode?: boolean) =>
  [
    { name: "MTCH", value: "Match", tooltip: "MTCH (Match)" },
    {
      name: "MBAM",
      value: "The provided creditor name closely resembles the account holder name",
      tooltip: "MBAM (May be a match)",
      hide: hideReasonCode,
    },
    { name: "NMTC", value: "No Match", tooltip: "NMTC (No Match)" },
    { name: "NOAP", value: "Validation check is not applicable", tooltip: "NOAP (Not applicable)" },
    {
      name: "NOTC",
      value: "Validation check has not been carried out",
      tooltip: "NOTC (Check has not been carried out)",
    },
  ].filter(v => !v.hide);

export const ACCOUNT_MATCH = [
  { name: "Yes", value: "Yes", title: "MTCH", tooltip: "MTCH (Matched validation check)" },
  { name: "No", value: "No", title: "NMTC", tooltip: "NMTC (Unmatched validation check)" },
];

export const ACCOUNT_STATUS = [
  { name: "Active", value: "ACTIVE", tooltip: "Accounts of individuals" },
  { name: "Inactive", value: "INACTIVE", tooltip: "Accounts of entities/organisations" },
];

export const REQUEST_SOURCE = [
  { name: "Batch", value: "BATCH", tooltip: "Bulk Payment Instruction Validation by Bank User" },
  { name: "Single", value: "SINGLE", tooltip: "Bank Customer" },
] as IOption[];

export const isJpmCountry = (country: CountryCode) => {
  return [
    CountryCode.IN,
    CountryCode.ID,
    CountryCode.DE,
    CountryCode.PK,
    CountryCode.BR,
    CountryCode.MX,
    CountryCode.NP,
    CountryCode.NG,
    CountryCode.VN,
    CountryCode.US,
  ].includes(country);
};

export const isBankN = (tenantName: string) => tenantName.includes("bankn");
export const isBankA = (tenantName: string) => tenantName === "banka";
export const isBankC = (tenantName: string) => tenantName === "bankc";
export const isKroo = (tenantName: string) => tenantName.includes("kroo");
export const isQnb = (tenantName: string) => tenantName.includes("qnb");
export const isJpm = (tenantName: string) =>
  tenantName.includes("accountvalidate") || tenantName.includes("accountverify");
export const isKbc = (tenantName: string) => tenantName.includes("kbc");
export const isAub = (tenantName: string) => tenantName.includes("ahliunited");
export const isScb = (tenantName: string) => tenantName.includes("shacombank");
export const isGtb = (tenantName: string) => tenantName.includes("gtbuk");
export const isFirstBank = (tenantName: string) => tenantName.includes("firstbankuk");
export const isPrivalgo = (tenantName: string) => tenantName.includes("privalgo");
export const isPnb = (tenantName: string) => tenantName.includes("pnbint");
export const isCmb = (tenantName: string) => tenantName.includes("cmb");
export const isMuchBetter = (tenantName: string) => tenantName.includes("muchbetter");
export const isNuapay = (tenantName: string) => tenantName.includes("nuapay");
export const isBoc = (tenantName: string) => tenantName.includes("bankofceylon");
export const isReliance = (tenantName: string) => tenantName.includes("reliancebankltd");
export const isEbury = (tenantName: string) => tenantName.includes("ebury");
export const isCanaraBank = (tenantName: string) => tenantName.includes("canarabank");
export const isBankAbc = (tenantName: string) => tenantName.includes("bank-abc");
export const isHlBank = (tenantName: string) => tenantName.includes("hl-cop");
export const isFurness = (tenantName: string) => tenantName.includes("furnessbs");
export const isHarpenden = (tenantName: string) => tenantName.includes("harpendenbs");
export const isSoldo = (tenantName: string) => tenantName.includes("soldo");
export const isQib = (tenantName: string) => tenantName.includes("qib");
export const isMelton = (tenantName: string) => tenantName.includes("themelton");
export const isUbi = (tenantName: string) => tenantName.includes("unionbankofindiauk");
export const isEab = (tenantName: string) => tenantName.includes("eabplc");
export const isFire = (tenantName: string) => tenantName.includes("fire");
export const isVitesse = (tenantName: string) => tenantName.includes("vitesse");
export const isSpdb = (tenantName: string) => tenantName.includes("spdblb");
export const isZenith = (tenantName: string) => tenantName.includes("zenith-bank");
export const isBirmingham = (tenantName: string) => tenantName.includes("birminghambank");
export const isFidBank = (tenantName: string) => tenantName.includes("fidbankuk");
export const isPassto = (tenantName: string) => tenantName.includes("passto");
export const capitalize = (input: string = "") => input.charAt(0).toUpperCase() + input.slice(1);
